/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const { initGtmContainer } = require('@escapegamemaker/util/lib/googletagmanager')
exports.onClientEntry = () => {
  initGtmContainer({ gtmId: 'GTM-5DWCD88' })
}
