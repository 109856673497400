"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.pushToDataLayer = exports.initGtmContainer = void 0;
var DATA_LAYER_NAME = 'dataLayer';
var dataLayer = (window[DATA_LAYER_NAME] = window[DATA_LAYER_NAME] || []);
function initGtmContainer(args) {
    var gtmId = args.gtmId;
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != DATA_LAYER_NAME ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', DATA_LAYER_NAME, gtmId);
}
exports.initGtmContainer = initGtmContainer;
function pushToDataLayer(stuff) {
    dataLayer.push(stuff);
}
exports.pushToDataLayer = pushToDataLayer;
var wrapDispatcher_1 = require("./wrapDispatcher");
__createBinding(exports, wrapDispatcher_1, "default", "wrapDispatcher");
